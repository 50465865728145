import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {routing} from "./app.routing";
import {AppComponent} from "./app.component";
import {
    CurrentLanguageManager,
    LoginGuard,
    LoginPagePreventionGuard,
    MainOnlineProvidersModule,
    SessionDataProviderImpl
} from "@synisys/idm-ui-shell";
import {CurrentLanguageProvider, SessionDataProvider} from "@synisys/idm-session-data-provider-api-js";
import {HttpClientModule} from "@angular/common/http";
import {Ng2Webstorage} from "ngx-webstorage";
import {AsyncLocalStorageModule} from 'angular-async-local-storage';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule} from "@angular/forms";
import {
    ApplicationPropertiesService,
    HttpApplicationPropertiesService
} from "@synisys/idm-application-properties-service-client-js";
import {StoreModule} from "@ngrx/store";

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        routing,
        HttpClientModule,
        MainOnlineProvidersModule,
        AsyncLocalStorageModule,
        Ng2Webstorage,
        FormsModule,
        StoreModule.forRoot({}),
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        LoginPagePreventionGuard,
        LoginGuard,
        SessionDataProviderImpl,
        CurrentLanguageManager,
        {provide: CurrentLanguageProvider, useExisting: SessionDataProviderImpl},
        {provide: SessionDataProvider, useExisting: SessionDataProviderImpl},
        {provide: ApplicationPropertiesService, useClass: HttpApplicationPropertiesService}
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
