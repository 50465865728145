import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app.module';
import {enableProdMode, ViewEncapsulation} from '@angular/core';

if (process.env.NODE_ENV === "production") {
	// Production
	enableProdMode();
}

platformBrowserDynamic([
	{provide: 'imagesPath', useValue: 'images'},
	{provide: 'configPath', useValue: './app/config'}
]).bootstrapModule(AppModule, [{defaultEncapsulation: ViewEncapsulation.None}]);
